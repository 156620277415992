@font-face {
    font-family: 'Noe Display';
    src: url('NoeDisplay-BoldItalic.woff2') format('woff2'),
        url('NoeDisplay-BoldItalic.woff') format('woff'),
        url('NoeDisplay-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Noe Display';
    src: url('NoeDisplay-Bold.woff2') format('woff2'),
        url('NoeDisplay-Bold.woff') format('woff'),
        url('NoeDisplay-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Noe Display';
    src: url('NoeDisplay-RegularItalic.woff2') format('woff2'),
        url('NoeDisplay-RegularItalic.woff') format('woff'),
        url('NoeDisplay-RegularItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Noe Display';
    src: url('NoeDisplay-Regular.woff2') format('woff2'),
        url('NoeDisplay-Regular.woff') format('woff'),
        url('NoeDisplay-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

