@font-face {
    font-family: 'Mark';
    src: url('Mark-Extralight.woff2') format('woff2'),
        url('Mark-Extralight.woff') format('woff'),
        url('Mark-Extralight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mark';
    src: url('Mark-Hairline.woff2') format('woff2'),
        url('Mark-Hairline.woff') format('woff'),
        url('Mark-Hairline.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mark';
    src: url('Mark-Bold.woff2') format('woff2'),
        url('Mark-Bold.woff') format('woff'),
        url('Mark-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mark';
    src: url('Mark-Black.woff2') format('woff2'),
        url('Mark-Black.woff') format('woff'),
        url('Mark-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mark';
    src: url('Mark-Heavy.woff2') format('woff2'),
        url('Mark-Heavy.woff') format('woff'),
        url('Mark-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mark';
    src: url('Mark-Book.woff2') format('woff2'),
        url('Mark-Book.woff') format('woff'),
        url('Mark-Book.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mark';
    src: url('Mark-Thin.woff2') format('woff2'),
        url('Mark-Thin.woff') format('woff'),
        url('Mark-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mark';
    src: url('Mark-Regular.woff2') format('woff2'),
        url('Mark-Regular.woff') format('woff'),
        url('Mark-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mark';
    src: url('Mark-Light.woff2') format('woff2'),
        url('Mark-Light.woff') format('woff'),
        url('Mark-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mark';
    src: url('Mark-Medium.woff2') format('woff2'),
        url('Mark-Medium.woff') format('woff'),
        url('Mark-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mark';
    src: url('Mark-Book.woff2') format('woff2'),
    url('Mark-Book.woff') format('woff'),
    url('Mark-Book.ttf') format('truetype');
    font-weight: 450;
    font-style: normal;
    font-display: swap;
}
