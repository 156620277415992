.pill {
    background-color: transparent;
    padding: 6px 16px;
    border-radius: 999px;
    color: $lightGray;
    border: 1px solid $lightBackgroundOverlay;
    font-weight: 600;
    &.ant-tag-checkable-checked {
        background-color: white;
        color: $secondaryColor;
    }
}

.ant-tag-checkable:active {
    background-color: $secondaryColor;
}

.ant-tag-checkable:hover {
    color: $secondaryColor !important;
}
