.loader-modal .ant-modal-content {
  @apply bg-white rounded-lg shadow-lg;
}

.loader-content {
  @apply flex flex-col items-center justify-center p-8;
}

.loader-image-container {
  @apply relative w-64 h-64 mb-6;
}

.loader-image {
  @apply absolute top-0 left-0 w-full h-full object-cover rounded-lg;
}

.loader-message {
  @apply text-xl font-semibold text-center text-gray-800;
}