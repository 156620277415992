.mapMarkerStyle {
  background-color: white;
  width: 5rem;
  height: 1.5rem;
  display: block;
  left: -0.5rem;
  top: -0.5rem;
  position: relative;
  border-radius: 0.3rem;
  transform: rotate(45deg);
  border: 1px solid grey;
}

.mapMarkerStyle::after {
  position: absolute;
  content: "";
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  margin-left: -4px;
  margin-top: -4px;
  background-color: #000;
}

// Colors
$default: #999;
$blue: #28a6de;
$green: #028752;
$black: #000000;
$yellow: #ffc107;
$red: #f44336;

$selectedgreen: #17bf4a;
$primaryColor: #fce02a;

// Marker CSS
.marker {
  font-size: 12px;
  display: inline-block;
  padding: 3px 5px;
  background: $default;
  border-radius: 4px;
  color: #fff;
  position: relative;
  box-sizing: border-box;

  // Line
  &:before {
    position: absolute;
    width: 1px;
    height: 2px;
    top: 100%;
    left: 50%;
    content: "";
    background: $default;
  }

  // Positioning
  &.angled-left {
    &:before {
      top: calc(100% - 4px);
      left: -5px;
      height: 16px;
      transform: rotate(45deg);
    }

    &:after {
      left: -15px;
      top: calc(100% + 4px);
    }

    &.top {
      &:before {
        top: auto;
        bottom: calc(100% - 4px);
        transform: rotate(-45deg);
      }

      &:after {
        top: auto;
        bottom: calc(100% + 5px);
      }
    }
  }

  &.angled-right {
    &:before {
      top: calc(100% - 4px);
      right: -4px;
      left: auto;
      height: 16px;
      transform: rotate(-45deg);
    }

    &:after {
      right: -22px;
      left: auto;
      top: calc(100% + 5px);
    }

    &.top {
      &:before {
        top: auto;
        bottom: calc(100% - 4px);
        transform: rotate(45deg);
      }

      &:after {
        top: auto;
        bottom: calc(100% + 5px);
      }
    }
  }

  // Colors
  &.marker-green {
    background: $primaryColor;
    box-shadow: 0 0 2px #00000033;
    color: black;

    &:before {
      background-color: $primaryColor;
    }

    &:after {
      background: $primaryColor;
      border-color: lighten($primaryColor, 35%);
    }
  }

  &.marker-blue {
    background: $secondaryColor;
    box-shadow: 0 0 2px #00000033;
    color: black;

    &:before {
      background-color: $secondaryColor;
    }

    &:after {
      background: $secondaryColor;
      border-color: lighten($secondaryColor, 35%);
    }
  }

  &.marker-blue-selected {
    background: $blue;
    box-shadow: 0 0 2px #00000033;
    color: black;

    &:before {
      background-color: $blue;
    }

    &:after {
      background: $blue;
      border-color: lighten($blue, 35%);
    }
  }

  &.marker-green-selected {
    background: $green;
    // color: black;
    &:before {
      background-color: $green;
    }

    &:after {
      background: $green;
      border-color: lighten($green, 35%);
    }
  }

  &.marker-black {
    background: $black;

    &:before {
      background-color: $black;
    }

    &:after {
      background: $black;
      border-color: lighten($black, 35%);
    }
  }

  &.marker-yellow {
    background: $yellow;

    &:before {
      background-color: $yellow;
    }

    &:after {
      background: $yellow;
      border-color: lighten($yellow, 35%);
    }
  }

  &.marker-red {
    background: $red;

    &:before {
      background-color: $red;
    }

    &:after {
      background: $red;
      border-color: lighten($red, 35%);
    }
  }
}

.propertygreen {
  border-top: 6px solid #43be6d;
  border-bottom: 6px solid #d2d2d2;
}

.propertyyellow {
  border-top: 6px solid #fde02b;
  border-bottom: 6px solid #d2d2d2;
}

.whitelabel {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: #fff;
  z-index: 10;
  font-size: 14px;
}

.pubcoverpicture {
  max-height: 250px;
  height: 250px;
  position: relative;
  margin-right: -24px;
  margin-left: -24px;
  margin-top: -24px;
  margin-bottom: 12px;
  background-size: cover;
  background-position: center center;
}

.pubcoverpicture:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, transparent 75%, black 100%);
}

.pubbody {
  margin-left: -14px;
}

.listadogrisclaro {
  background: #ffffff;
  margin-left: -10px;
  margin-right: -24px;
  margin-top: 30px;
}

.listadogrisclaro li {
  border-bottom: 0 !important;
}

.listadogrisclaro li:nth-child(odd) {
  background: #f6fafb;
}

.listadogrisclaro div.titulo {
  font-weight: bold;
  font-size: 14px;
  color: #373737;
  padding-left: 10px;
}

.listadogrisclaro div.detalle {
  font-size: 14px;
  color: #373737;
  padding-left: 10px;
}

.listadogrisoscuro {
  background: #757d7d;
  margin-left: -10px;
  margin-right: -24px;
  margin-top: 30px;
}

.listadogrisoscuro li {
  border-bottom: 0 !important;
}

.listadogrisoscuro li:nth-child(odd) {
  background: #838c8c;
}

.listadogrisoscuro div.titulo {
  font-weight: bold;
  font-size: 14px;
  color: #ffffff;
  padding-left: 10px;
}

.listadogrisoscuro div.detalle {
  font-size: 14px;
  color: #ffffff;
  padding-left: 10px;
}

.headerAdjustments {
  background-color: #ffffff !important;
  padding: 4px !important;
}

.custom-tooltip {
  background: #ffffff 0 0 no-repeat padding-box;
  box-shadow: 0 3px 6px #00000029;
  border-radius: 0 0 4px 4px;
  border-top: 3px solid $selectedgreen;
  opacity: 1;
  width: 300px;
}

.tooltip-price {
  text-align: left;
  font: normal normal bold 14px/16px Mark;
  letter-spacing: 0;
  color: #000000;
  padding-top: 20px;
}

.tooltip-desc {
  text-align: left;
  font: normal normal bold 12px/40px Mark;
  letter-spacing: 0;
  color: #000000;
}

//viewcms styles

.yellowdivupper {
  z-index: 1;
  height: 400px !important;
  top: 100px;
  left: 53px;
  width: 570px;
  position: absolute;
  background-color: #fce02a;
}

.yellowdivupper-mobile {
  z-index: 1;
  height: 400px !important;
  position: static;
  width: 100%;
  background-color: #fce02a;
}

.ant-steps-item-description {
  font-size: 15px;
}

.ant-steps-item-wait,
> .ant-steps-item-container,
> .ant-steps-item-content,
> .ant-steps-item-description {
  padding-top: 2px;
}

@media print {
  div.mapboxgl-control-container {
    display: none;
  }
}

.border-green {
  border-color: $primaryColor;
}

.border-green-selected {
  border-color: $green;
}
