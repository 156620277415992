.ant-drawer-header {
  // padding: 5px 0;
}

.ant-drawer-right {
  // z-index: 9999999999 !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: $lightGray;
}

.ant-tabs-ink-bar {
  background-color: $black;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item-group-title {
  color: $lightGray;
  font-weight: bold;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item-group {
  border-bottom: 1px solid $lightBackground;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item {
  padding-left: 16px;
}

.jobs-tabs {
  .ant-tabs-ink-bar {
    background-color: $primaryColor !important;
  }
}

.ant-input,
.ant-select-selector,
.ant-picker {
  border-radius: 4px;
}

.pulppo-badges {
  .ant-badge-count {
    z-index: 999999;
    font-size: 10px;
  }
}

.ant-spin-container{
  height: 100%;
}

.no-background {
  .ant-table-tbody {
    background: transparent !important;
  }

  .ant-pagination-item, .ant-pagination-next, .ant-pagination-prev, .ant-pagination-item-link {
    a, button {
      color: #838C8C;
    }

    border-radius: 0;
    border: none;
    background-color: transparent;

  }

  .ant-pagination-item-active {
    background-color: #000;
    border-radius: 0;
    color: white;

    a {
      color: white;
    }
  }
}

.ant-input,
.ant-select-selector,
.ant-input-number-input {
  @media screen and (max-width: $md) {
    font-size: 1.2em;
  }
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item.show-all {
  text-align: center;
  cursor: default;
}

// a.ant-btn {
//   padding-top: 0 !important;
// }

.ant-image-preview-img-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  &::before {
    height: 0;
  }
}

.ant-image-preview-img-wrapper
.certain-category-search-dropdown
.ant-select-dropdown-menu {
  max-height: 300px;
}

.ant-form-item-label {
  font-weight: 500;
}

.ant-menu-item-icon {
  width: 30px;
  min-width: 30px !important;
  height: 30px;
  border-radius: 50%;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.ant-menu-inline {
  .ant-menu-item-selected,
  .ant-menu-submenu-selected {
    font-weight: 600 !important;

    .ant-menu-item-icon {
      background-color: $primaryColor;
    }
  }
}

.ant-menu-item-selected,
.ant-menu-submenu-selected {
  background-color: transparent !important;
}

.ant-menu-inline-collapsed > .ant-menu-item {
  padding: 0 calc(50% - 40px / 2) !important;
}

.ant-menu-submenu-arrow {
  display: none;
}

.ant-menu-submenu-title {
  &:hover {
    color: black !important;
  }

  padding-right: 0 !important;
}

.ant-layout-sider-collapsed {
  .ant-menu-submenu-title {
    padding: 0 calc(50% - 40px / 2) !important;
  }
}

.ant-menu-inline > .ant-menu-item,
.ant-menu-submenu-title {
  padding-left: 12px !important;
}

.ant-menu-inline .ant-menu-item::after,
.ant-menu-submenu .ant-menu-submenu::after {
  border-right: 3px solid $primaryColor;
}

.ant-menu-item-selected,
.ant-menu-submenu-selected {
  color: $black;
}

.ant-menu-item:hover,
.ant-menu-submenu:hover {
  color: $lightGray !important;
}

.ant-drawer-content {
  overflow: visible;
  // box-shadow: 0px 0px 5px lightgray;
}

.ant-menu-item.ant-menu-item-selected,
.ant-menu-submenu.ant-menu-submenu-selected {
  border-right: 3px solid $primaryColor;
}

.ant-btn-primary {
  background-color: $primaryColor;
  color: $black;
  text-shadow: none;
  box-shadow: none;
  border: 1px solid $primaryColor;
  padding: 0 20px;
  border-radius: 0;

  &:active {
    background-color: $primaryColorDark;
    border: 1px solid $primaryColorDark;
  }

  &:hover,
  &:focus {
    background-color: $primaryColor50;
    color: $black;
    border: 1px solid $primaryColor50;
  }
}

.ant-btn-link {
  color: $secondaryColor;
}

.ant-scroll-number {
  text-align: left;
}

.ant-upload-select {
  border-radius: 50% !important;
  // padding: 2px;
}

.ant-steps-item-description {
  font-size: 10px;
  width: 100%;
  color: $lightGray !important;
}

.ant-timeline-item {
  padding-bottom: 6px !important;
}

.ant-form-item {
  margin-bottom: 8px;
}

.antd-whatsapp-tab {
  .ant-tabs-ink-bar {
    background-color: $whatsappColor;
  }
}
.ant-input-affix-wrapper{
  &:not(.ant-input-affix-wrapper-disabled){
    &:hover {
      border-color: black;
      color: black;
      box-shadow:unset;
    }

  }

}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector{
  border-color: black;
  color: black;
  box-shadow:unset;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: black;
  color: black;
  box-shadow:unset;
}

.ant-select-focused{
  &:not(.ant-select-disabled){
    border-color: black;
    color: black;
    box-shadow:unset;
  }
}
.ant-select{
  &:not(.ant-selec-disabled){
    &:hover{
      border-color: black;
      color: black;
      box-shadow:unset;
    }
  }
}
.ant-input-affix-wrapper-focused{
  border-color: black;
  color: black;
  box-shadow: unset;
}
.ant-select-focused {
  border-color: black;
  color: black;
  box-shadow: unset;
}
.ant-btn-default, .ant-select, .ant-input, .ant-input-affix-wrapper {
  &:active, &:hover, &:focus, &::selection {
    border-color: black;
    color: black;
    box-shadow: unset;
  }
}


.ant-btn-default, .ant-select, .ant-input, .ant-input-affix-wrapper {
  &:active, &:hover, &:focus, &::selection {
    border-color: black;
    color: black;
    box-shadow: 'none';
  }
}

.antd-email-tab {
  .ant-tabs-ink-bar {
    background-color: $emailColor;
  }
}

.ant-tabs-content-holder {
  //   overflow-y: scroll;
  // position: relative;
}

.ant-timeline-item-left {
  .bg-blue-100 {
    margin-right: 10px;
  }
}

a.ant-typography,
.ant-typography a {
  color: $secondaryColor;
}

.notification-center {
     .ant-switch {
        background-color: #cccccc;
    }
    .ant-switch-checked {
        background-color: #000;
    }
}

.ant-input-number-input-wrap {
  width: 100%;
}

.ant-picker-calendar-header .ant-picker-calendar-mode-switch {
  display: flex;
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner{
  background-color: black;
  color: white;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before{
  border-color: black;
}

.ant-badge-count-sm {
  display: flex;
  justify-content: center;
}

.property.ant-drawer {
  .ant-drawer-body {
    padding-top: 0;
  }

  @media screen and (min-width: $md) {
    // right: 50px !important;
    // margin-right: 50px !important;
    // padding-right: 50px !important;
    // width: 70% !important;
  }
}

.property {
  .ant-drawer-header-title {
    flex-direction: row-reverse;
    width: 100%;
  }
}

.property .ant-drawer-header {
  border-bottom: none;
}

.ant-table-cell-row-hover {
  background-color: transparent !important

}


.ant-scroll-number {
  display: flex;
  justify-content: center;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: white;
  border-color: white;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid green;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: " ";
}

.ant-layout {
  background: transparent;
}

.ant-layout-header {
  background: transparent;
}

.ant-timeline.ant-timeline-alternate
.ant-timeline-item-left
.ant-timeline-item-content,
.ant-timeline.ant-timeline-right
.ant-timeline-item-left
.ant-timeline-item-content,
.ant-timeline.ant-timeline-label
.ant-timeline-item-left
.ant-timeline-item-content {
  width: calc(50% - 26px);
}

.ant-avatar-lg {
  min-width: 40px;
}

.max-height {
  .ant-modal-content {
    max-height: 100%;
    height: 100%;
    overflow-y: scroll;
  }

  .ant-modal-footer {
    display: none;
  }
}

.ant-tooltip-inner {
  color: white !important;
  background-color: black !important;
  border-radius: 0;
}
.custom-tooltip-chat .ant-tooltip-inner {
  color: black !important;
  background-color: white !important;
  border: 0.2px solid #212322;
  font-size: 12px;
  white-space: nowrap;
  min-width: fit-content;
  padding: 8px;
  transform: translateX(-5%);
}
.custom-tooltip-chat .ant-tooltip-arrow {
  margin-left: -6%;
}

.opportunity {
  &.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
    align-items: flex-end;
  }

  .ant-tabs-extra-content {
    flex: auto;
    // width: 100%;
  }
}
.ant-switch-checked{
  background:#EAB713 !important;
}

.ant-radio-button-wrapper:hover {
  color: #EAB713 !important;
  opacity: 0.5;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: black !important;
  border-color: #EAB713 !important;
  background:#EAB713 !important;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: 0 0 0 3px rgba(198, 155, 29, 0.268);
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: black !important;
}

.ant-table-thead > tr > th {
  background: transparent !important;
  font-weight: 600;
}

.chat {
  // height: 100%;
  ul {
    height: 100%;
    // overflow-y: scroll;
  }

  .ant-timeline-item-tail {
    display: none;
  }

  .ant-timeline-item-head {
    display: none;
  }

}

.chat-select-all {
  .ant-select-arrow {
    color: white !important;
  }

  .ant-select-selector {
    background-color: black !important;
    color: white !important;
    border-radius: 17px !important;
  }
}

.chat-select-whatsapp {
  .ant-select-arrow {
    color: white !important;
  }

  .ant-select-selector {
    background-color: $whatsappColor !important;
    color: white !important;
    border-radius: 17px !important;
  }
}

.chat-select-emails {
  .ant-select-arrow {
    color: white !important;
  }

  .ant-select-selector {
    background-color: $emailColor !important;
    color: white !important;
    border-radius: 17px !important;
  }
}

.ant-progress-steps-item {
  width: 18.5% !important;
  height: 4px !important;
}

.ant-menu-light {
  .ant-menu-item-active,
  .ant-menu-submenu-selected {
    color: initial !important;
  }
}

.phone {
  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }
}

.ant-select-item-option-content {
  display: flex;
  align-items: center;
}

.ant-form-item-label label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.collection {
  .ant-page-header-heading-title {
    white-space: normal;
  }
}

.zoom {
  background-color: #00000088;
  padding: 4px 10px;
  border-radius: 40px;
  margin-bottom: 10px;

  .ant-slider-track {
    background-color: white !important;
  }

  .ant-slider-handle {
    border-color: white;
  }
}

.contact-list {
  @media screen and (min-width: $md) {
    width: 60% !important;
    // width: 70% !important;
  }
}

.ant-tag-close-icon {
  display: flex;
  align-items: center;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background: none;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
  color: transparent;
  border: none;
  background: none;
}

.full-height {
  .ant-select-selector {
    height: 100%;
  }
}

.rounded-l-md {
  .ant-input-number-group-addon {
    border-radius: 0 5px 5px 0;
    background-color: #D9D9D9;
    font-size: 14px;
  }
}

.border-r-none.rounded-r-none.rounded-l-md > .ant-select-selector {
  border-radius: 0.375rem 0 0 0.375rem !important;
  border-right: none;
}

.rounded-md > .ant-select-selector {
  border-radius: 0.375rem !important;
}

.font-medium .ant-select-selection-item {
  font-weight: 500 !important;
}

.ant-table {
  background: transparent !important;

  // .ant-table-placeholder:hover {
  //     background: transparent !important;
  // }
  .ant-table-tbody {
    background-color: #fff;
  }

  .ant-table-thead {
    .ant-table-cell {
      background-color: transparent;
    }
  }
}

.workspace .ant-tabs-nav {
  margin: 0;
}

.ant-steps-dot .ant-steps-item-content {
  // width: 100px !important;
}

.ant-typography-edit-content {
  // margin-top: 20px;
  // margin: 10px;
  &.level-3 {
    textarea {
      font-weight: 600;
      font-size: 24px;
      line-height: 1.45;
    }
  }

  // margin-right: 10px;
  margin-bottom: 0.5em !important;

  textarea {
    // margin-top: 10px;
    // margin-left: 14px;
    &:focus {
      outline: none !important;
      border: none !important;
      box-shadow: none !important;
    }

    border: none;
    padding: 8px 0 0 12px;
    height: auto;
    max-height: 10vh;
    left: 0 !important;
    margin-top: 0 !important;
    outline: none;
    background-color: white !important;
  }
}

.upload-flex {
  .ant-spin-container > span {
    display: block;
    @media screen and (min-width: $md) {
      display: flex;
    }

    & > .ant-upload {
      flex: 1;
    }

    & > .ant-upload-list {
      padding-left: 10px;
      flex: 1;
      color: white !important;

      .anticon-paper-clip {
        color: white;
      }
    }
  }

  .anticon-delete {
    color: white;
  }

  .ant-upload-list-item:hover .ant-upload-list-item-info {
    background-color: #f5f5f533;
  }
}


.referral {
    .ant-table-cell {
        padding-left: 0;
        color: #838C8C;
        padding-bottom: 5px;
        &::before {
            display: none;
        }
    }
}

.full-label {
    span:nth-child(1){
        flex-shrink: 0;
    }
    span:nth-child(2){
        flex: 1;
    }


}

 .ant-radio-inner::after {
         background-color: #000;
    }
    .ant-radio-checked .ant-radio-inner {
        border-color: #000;
    }
    .ant-radio-checked::after {
        border-color: #000 !important;
    }
    .ant-form input[type='file']:focus, .ant-form input[type='radio']:focus, .ant-form input[type='checkbox']:focus {
        outline-color: #000 !important;
    }
    .ant-radio-wrapper:hover .ant-radio {
        border-color: #000 !important;
    }
    .ant-radio-input:focus + .ant-radio-inner {
        border-color: #000 !important;
    }

    .ant-radio-input:focus + .ant-radio-inner {
        box-shadow: none;
    }

    .custom-badge .ant-badge-multiple-words {
        padding: 0 !important;
    }

    .custom-badge .ant-badge-count {
      box-shadow: none;
    }

