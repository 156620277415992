.add-contact {
    width: 60% !important;
    @media screen and (max-width: $md) {
        top: 10px;
        width: 90% !important;
    }
    .ant-modal-footer {
        display: none;
    }
    .ant-modal-body {
        padding-bottom: 8px !important;
    }
}
