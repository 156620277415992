@font-face {
    font-family: "pulppo-icon-font";
    src: url("./pulppo-icon-font.ttf?xkqy5b") format("truetype"),
        url("./pulppo-icon-font.woff?xkqy5b") format("woff"),
        url("./pulppo-icon-font.svg?xkqy5b#pulppo-icon-font") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "pulppo-icon-font" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-iso-circle:before {
    content: "\e900";
}
.icon-iso:before {
    content: "\e901";
}
.icon-add-circle:before {
    content: "\e902";
}
.icon-add:before {
    content: "\e903";
}
.icon-remove-circle:before {
    content: "\e904";
}
.icon-remove:before {
    content: "\e905";
}
.icon-close-circle:before {
    content: "\e906";
}
.icon-close:before {
    content: "\e907";
}
.icon-arrow-back:before {
    content: "\e908";
}
.icon-arrow-forward:before {
    content: "\e909";
}
.icon-arrow-down:before {
    content: "\e90a";
}
.icon-arrow-up:before {
    content: "\e90b";
}
.icon-search:before {
    content: "\e90c";
}
.icon-trash:before {
    content: "\e90d";
}
.icon-share:before {
    content: "\e90e";
}
.icon-filters:before {
    content: "\e90f";
}
.icon-menu:before {
    content: "\e910";
}
.icon-page-back:before {
    content: "\e911";
}
.icon-arrow-go-circle:before {
    content: "\e912";
}
.icon-arrow-go:before {
    content: "\e913";
}
.icon-zoom-collapse:before {
    content: "\e914";
}
.icon-zoom-expand:before {
    content: "\e915";
}
.icon-full-screen:before {
    content: "\e929";
}
.icon-view-thumbs:before {
    content: "\e916";
}
.icon-view-list:before {
    content: "\e917";
}
.icon-media-map:before {
    content: "\e918";
}
.icon-media-photo:before {
    content: "\e919";
}
.icon-media-video:before {
    content: "\e91a";
}
.icon-media-virtual-tour:before {
    content: "\e91b";
}
.icon-media-plans:before {
    content: "\e91c";
}
.icon-media-street-view:before {
    content: "\e91d";
}
.icon-city:before {
    content: "\e91e";
}
.icon-user:before {
    content: "\e91f";
}
.icon-draw:before {
    content: "\e920";
}
.icon-train:before {
    content: "\e921";
}
.icon-subway:before {
    content: "\e922";
}
.icon-bus:before {
    content: "\e923";
}
.icon-tech-valuator:before {
    content: "\e924";
}
.icon-tech-conecta:before {
    content: "\e925";
}
.icon-tech-crm:before {
    content: "\e926";
}
.icon-tech-workspace:before {
    content: "\e927";
}
.icon-tech-marketing:before {
    content: "\e928";
}
.icon-email:before {
    content: "\e92b";
}
.icon-whatsapp:before {
    content: "\e92a";
}
.icon-facebook:before {
    content: "\e92c";
}
.icon-twitter:before {
    content: "\e92d";
}
.icon-instagram:before {
    content: "\e92e";
}
.icon-linkedin:before {
    content: "\e92f";
}
.icon-link:before {
    content: "\e931";
}
.icon-copy:before {
    content: "\e930";
}
.icon-saving:before {
    content: "\e932";
}
.icon-saved:before {
    content: "\e933";
}
.icon-config:before {
    content: "\e934";
}
.icon-new-tab:before {
    content: "\e935";
}
