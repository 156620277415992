$candlelight: #fdda25;
.Footer {
  margin: -24px;
  position: relative;
  background-color: #3d4138;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // width: 100%;
  max-height: 60vh;

  .cls-1 {
    fill: #ffffff !important;
  }

  .cls-2 {
    fill: #000000 !important;
  }

  .pattern {
    content: "";
    width: 100%;
    height: 44px;
    bottom: 0;
    left: 0;
    background-image: repeating-linear-gradient(
                    -45deg,
                    transparent,
                    transparent 6px,
                    $lightBackgroundOverlay 8px,
                    $lightBackgroundOverlay 8px
    );
    opacity: 0.5;
    background-size: 22px;
  }

  .container {
    display: flex;
    align-items: flex-start;
    max-width: 100%;
    padding: 57px 120px;
    margin: 0;
    justify-content: space-between;

    .col-12 {
      width: auto;
    }

    .links {
      display: flex;
      flex-direction: column;

      a {
        color: #ffffff;
        text-decoration: none;
        margin-bottom: 15px;
        font-size: 16px;
        width: max-content;

        &:hover {
          color: $candlelight;
        }
      }
    }

    .socialMedia {
      display: flex;
      align-items: center;
      width: auto;

      a {
        margin-right: 27px;
        text-decoration: none;

        span {
          color: #ffffff;
          font-size: 20px;

          &:hover {
            color: $candlelight;
          }
        }

        &:last-child {
          margin: 0;
          // width: 175px;
          background-color: white;

          span {
            color: black;
          }

          &:hover {
            background-color: $candlelight;
          }
        }
      }
    }
  }

  @media screen and (max-width: 992px) {
    .container {
      flex-direction: column;
      padding: 20px 10px;

      .socialMedia {
        display: flex;
        align-items: center;
        width: 100%;
        flex-direction: column;
        margin-bottom: 20px;
      }

      .links {
        margin-bottom: 20px;

        a:nth-last-child(2) {
          margin-top: 20px;
        }

        a:nth-last-child(-n + 2) {
          font-weight: 300;
        }
      }

      .col-12 {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        a {
          text-decoration: none;

          span {
            color: white;
            font-size: 20px;

            &:first-child {
              margin: 15px;
            }
          }
        }
      }
    }
    .pattern {
      content: "";
      width: 100%;
      height: 44px;
      bottom: 0;
      left: 0;
      background-image: repeating-linear-gradient(
                      -45deg,
                      transparent,
                      transparent 6px,
                      $lightBackgroundOverlay 8px,
                      $lightBackgroundOverlay 8px
      );
      opacity: 0.5;
      background-size: 22px;
    }
  }
}
