@font-face {
    font-family: "Nunito Sans";
    src: url("NunitoSans-VariableFont_YTLC,opsz,wdth,wght.ttf") format("truetype supports variations"),
    url("NunitoSans-VariableFont_YTLC,opsz,wdth,wght.ttf") format("woff2-variations");
    font-weight: 100 1000;
}

@font-face {
    font-family: 'Nunito Sans';
    src: url('NunitoSans-Italic-VariableFont_YTLC,opsz,wdth,wght.ttf') format('truetype supports variations'),
    url('NunitoSans-Italic-VariableFont_YTLC,opsz,wdth,wght.ttf') format('woff2-variations');
    font-style: italic;
    font-weight: 100 1000;
}
