$primaryColor: #EAB713;
$primaryColorDark: #99881c;
$primaryColor50: #fce02a80;
$secondaryColor: #6bbacf;
$lightBackground: #f6fafb;
$success: #44be6d;
$lightBackgroundOverlay: #e0e4e5de;
$lightGray: #838c8c;
$white: #ffffff;
$black: #000000;
$md: 768px;
$whatsappColor: #44be6d;
$emailColor: #097e9d;
$gunsmoke: #838c8c; // https://chir.ag/projects/name-that-color/#838C8C
$candlelight: #fdda25; // https://chir.ag/projects/name-that-color/#FDDA25
$confirmationGreen: #02B1A9;
$patternSize: 28px;
$footerHeight: 72px;