.PropertyMedia {
  margin-top: 0.6%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .glide {
    .glide__track {
      aspect-ratio: 1.6/1;
    }

    .glide__arrow {
      height: 50px;
      width: 50px;
      align-items: center;
      border: none;
      border-radius: 100%;
      background-color: rgba(0, 0, 0, 0.384);
      transition: background-color 0.5s linear;
      font-size: 25px;
      display: flex;
      justify-content: center;

      &:hover {
        background-color: black;
      }
    }

    .glide__arrow--right {
      right: 4px;
    }

    .glide__arrow--left {
      left: 4px;
    }

    .glide__bullets {
      bottom: 23px;

      .glide__bullet {
        width: 12px;
        height: 12px;
      }

      .glide__bullet--active {
        background-color: $candlelight;
      }
    }
  }

  @media screen and (max-width: 992px) {
    .pd-property-media {
      margin-top: 30px;
      margin-bottom: 30px;
      /*       margin-left: -24px;
margin-right: -24px; */
      width: calc(100% + 48px);
    }
  }
  @media screen and (width: 768px) {
    .pd-property-media {
      margin-top: 30px;
      margin-bottom: 30px;
      margin-left: 0;
      margin-right: 0;
      width: 100%;
    }
  }

  .icon-full-screen {
    position: absolute;
    bottom: 4px;
    right: 4px;
    background-color: rgba(0, 0, 0, 0.384);
    font-size: 25px;
    width: 50px;
    height: 50px;
    margin: auto;
    text-align: center;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    color: white;
    cursor: pointer;
    transition: all 0.5s linear;
    border: none;

    @media screen and (max-width: 992px) {
      bottom: 34px;
    }

    &:hover {
      background-color: black;
    }
  }

  .pd-property-media {
    position: relative;
    background-color: grey;
    aspect-ratio: 3/2;
    width: 100%;
    /* move */
    background-size: cover;
    background-position: center;
  }

  .pd-property-controls {
    display: flex;
    height: 100%;
    margin-top: 1em;

    .inactiveTab {
      opacity: 0.2;
      cursor: auto !important;
    }

    .controls-item-list {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-right: 28px;
      cursor: pointer;

      p {
        margin: 0;
        font-size: 14px;
      }

      span {
        font-size: 20px;
      }

      &:hover {
        color: $gunsmoke;
      }
    }
  }
}

.PropertyMedia-modal {
  &-active {
    animation: inner-modal 0.5s ease-in 0s 1 normal none;
    display: flex;
  }

  &-hidden {
    animation: outter-modal 0.5s ease-in 0s 1 normal none;
    visibility: hidden;
  }

  &-glide_bg:before {
    background: repeating-linear-gradient(
                    -45deg,
                    transparent,
                    transparent 6px,
                    $lightBackgroundOverlay 8px,
                    $lightBackgroundOverlay 8px
    );
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    opacity: 0.5;
  }

  .ImagePanZoom {
    height: 100%;
    width: 100vw;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .component {
    &-active {
      display: initial;
      overflow-y: scroll;
      scrollbar-width: none; /* For Firefox */
      -ms-overflow-style: none; /* For Internet Explorer and Edge */
      &::-webkit-scrollbar {
        width: 0;
      }
    }
  }

  .noScroll {
    overflow-y: unset;
  }

  .BluePrintGallery {
    .SliderG-Section-insideModal {
      display: flex;

      &-hide {
        display: none;
      }
    }

    overflow-x: scroll;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }

    .GridImagesSection {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      height: auto;
      grid-gap: 10px;
      gap: 10px;
      overflow-x: auto;

      &-hide {
        display: none;
      }
    }

    .Image-wrapper {
      background-color: grey;

      .Image {
        aspect-ratio: 3/2;
        display: flex;
        justify-content: center;
        align-items: center;
        //background-size: contain;
        //background-position: center;
        //background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        // Fallback (current, using padding hack)
        @supports not (aspect-ratio: 3/2) {
          div::before {
            float: left;
            padding-top: 66.67%;
            content: "";
          }

          div::after {
            display: block;
            content: "";
            clear: both;
          }
        }

        .icon-zoom-expand {
          opacity: 0;
        }
      }

      &:hover {
        .icon-zoom-expand {
          font-size: 40px;
          background-color: rgba(0, 0, 0, 0.4);
          width: 80px;
          height: 80px;
          border-radius: 100%;
          display: -moz-box;
          display: flex;
          -moz-box-pack: center;
          justify-content: center;
          -moz-box-align: center;
          align-items: center;
          color: white;
          opacity: 1;
          -webkit-transition: opacity 0.5s linear;
          -moz-transition: opacity 0.5s linear;
          transition: opacity 0.5s linear;
          cursor: pointer;
        }
      }
    }
  }

  .VideoGallery {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 92%;

    .picture-details {
      justify-content: center;
      align-items: center;
      padding: 4px 124px;
      @media screen and (max-width: 992px) {
        padding: 4px 40px;
      }
    }
  }

  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 999999;
  transition: all 0.5s linear;
  flex-direction: column;

  .picture-modal {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    height: auto;
    gap: 10px;
    overflow-x: auto;
    @media screen and (max-width: 992px) {
      gap: 5px;
    }
    @media screen and (max-width: 575px) {
      gap: 5px;
      grid-template-columns: repeat(2, 1fr);

      @media screen and (max-width: 374px) {
        gap: 5px;
        grid-template-columns: repeat(1, 1fr);
      }
    }

    .picture-grid {
      position: relative;
      background-color: grey;
      width: 100%;
      background-size: cover;
      background-position: center;
      display: flex;
      justify-content: center;
      align-items: center;
      aspect-ratio: 3/2;

      .icon-zoom-expand {
        opacity: 0;
      }

      &:hover {
        .icon-zoom-expand {
          position: absolute;
          font-size: 40px;
          background-color: rgba(0, 0, 0, 0.4);
          width: 80px;
          height: 80px;
          border-radius: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;
          opacity: 1;
          transition: opacity 0.5s linear;
          cursor: pointer;
        }
      }
    }
  }

  .menu-modal {
    display: flex;
    flex-direction: row;
    max-height: 60px;
    min-height: 60px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0 80px;
    z-index: 10;
    background-color: #ffffff;
    box-shadow: 0 0 4px #c6c6c6;

    @media screen and (max-width: 992px) {
      padding: 0 20px;
    }

    button {
      border: none;
      background-color: transparent;
      color: $gunsmoke;
      font-size: 18px;
      font-weight: 400;

      @media screen and (max-width: 992px) {
        box-shadow: -4px -4px 12px 4px #ffffff;
        width: 50px;
        height: 50px;
        text-align: right;
        z-index: 1;
      }
    }

    .pd-property-controls {
      display: flex;
      height: 100%;
      @media screen and (max-width: 992px) {
        overflow-y: auto;
      }

      .controls-item-list {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: 30px;
        cursor: pointer;

        p {
          margin: 0;
          font-size: 14px;
          width: max-content;
        }

        span {
          font-size: 20px;
        }

        &:hover {
          color: $gunsmoke;
        }
      }

      .active {
        border-bottom: 3px solid;
        cursor: none;
        //background: #6f6f6f2b;
        padding-left: 3px;
        padding-right: 3px;
      }

      .inactiveTab {
        opacity: 0.2;
        cursor: auto !important;
      }
    }
  }

  .picture-details {
    position: relative;
    /*   overflow: auto; */
    padding: 0 124px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;

    .picture-screen-zoom {
      width: 100%;
      aspect-ratio: 3/2;
      background-position: center;
      overflow: hidden;
      position: absolute;
      background-size: contain;
      background-repeat: no-repeat;

      @media screen and (max-width: 992px) {
        position: relative;
        overflow: auto;
      }
    }

    .glide {
      height: 90%;

      .glide__track {
        height: 100%;

        .glide__slides {
          height: 100%;

          .picture-fullscreen {
          }
        }
      }
    }

    .content-menu {
      display: grid;
      justify-items: start;
      align-items: center;
      width: calc(100% - 264px);
      grid-template-columns: repeat(2, 1fr);
      cursor: auto;

      .icon-zoom-collapse {
        //background-color: #0000006b;
        background: black;
        color: white;
        font-size: 22px;
        width: 34px;
        height: 34px;
        margin: 0 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        cursor: pointer;
        transition: background-color 0.5s linear;

        &:hover {
          background-color: black;
        }
      }

      p {
        margin: 20px 0;
        cursor: pointer;
        font-size: 12px;
        display: flex;
        align-items: center;
        width: 10em;

        span {
          font-size: 20px;
          margin-right: 11px;
        }

        &:hover {
          color: $gunsmoke;
        }
      }

      .menu-range {
        &--wrapper {
          display: inline-flex;
          justify-content: center;
          align-items: center;
        }

        background-color: #0000006b;
        display: flex;
        justify-content: center;
        width: 210px;
        height: 34px;
        border-radius: 17px;
        right: 45%;
        bottom: 5vh;
        margin: 10px 0;
        align-items: center;
        transition: background-color 0.5s linear;

        &:hover {
          background-color: black;
        }

        span {
          color: white;
          font-size: 18px;
        }

        input[type="range"] {
          -webkit-appearance: none;
          width: 130px;
          height: 1px;
          border-radius: 10px;
          margin: 0 15px;
          outline: none;

          &::-webkit-slider-thumb {
            -webkit-appearance: none;
            background: rgb(255, 255, 255);
            width: 14px;
            height: 14px;
            border-radius: 10px;
          }
        }
      }
    }

    .picture-fullscreen {
      position: relative;
      background-color: transparent;
      // aspect-ratio: 3/2;
      height: calc(100%);
      background-size: contain;
      background-position: center;
      overflow: auto;
      background-repeat: no-repeat;
    }

    .glide {
      .glide__arrow {
        height: 50px;
        width: 50px;
        align-items: center;
        border: none;
        border-radius: 100%;
        background-color: rgba(0, 0, 0, 0.384);
        transition: background-color 0.5s linear;
        font-size: 25px;
        display: flex;
        justify-content: center;

        &:hover {
          background-color: black;
        }
      }

      .glide__arrow--right {
        right: -114px;
      }

      .glide__arrow--left {
        left: -114px;
      }

      .glide__bullets {
        bottom: 26px;

        .glide__bullet {
          width: 12px;
          height: 12px;
        }

        .glide__bullet--active {
          background-color: $candlelight;
        }
      }
    }

    @media screen and (max-width: 992px) {
      padding: 0;
      justify-content: space-between;

      .glide {
        height: auto;
        aspect-ratio: 3/2;

        .glide__arrow {
          height: 35px;
          width: 35px;
        }

        .glide__arrow--left {
          left: -38px;
        }

        .glide__arrow--right {
          right: -37px;
        }

        .picture-fullscreen {
          height: auto;
          aspect-ratio: 3/2;
        }
      }

      .content-menu {
        display: flex;
        width: 100%;
        flex-direction: column-reverse;

        p {
          margin: 10px 0;
        }
      }
    }
  }
}

@keyframes inner-modal {
  0% {
    opacity: 0;
    transform: translateY(100vh);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes outter-modal {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(100vh);
  }
}
